import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Layout, DemoDaySummary, NextLaunch } from '../components'
import { orderBy, takeWhile, uniq, map, } from 'lodash'
import { set } from 'lodash/fp'
import { contributorCount, useSiteMetadata } from "../utils"
import { DEMO_DAYS_DISPLAY_BLOCK_SIZE } from '../utils/config'

const DemoDays = ({ data }) => {
  const { title: siteTitle } = useSiteMetadata()
  const [displayLimit, setDisplayLimit] = useState(DEMO_DAYS_DISPLAY_BLOCK_SIZE)
  const demoDays = data.allWpDemoDay.nodes.map((node) => {
    return set('fields.launchDate', new Date(node.fields.launchDate), node)
  })
  const sortedDemoDays = orderBy(demoDays, ({ fields: { launchDate } }) => launchDate, ['desc'])
  const missions = data.allWpMission.nodes
  const users = data.allWpUser.nodes
  const demoDayIdsForMissions = uniq(map(missions, 'fields.demoDay.id'))

  return (
    <Layout>
      <Helmet title={`Demo Days - ${siteTitle}`} bodyAttributes={{ class: 'demo_days' }} />
      <DemoDaysHeader />
      <DemoDaysStory />
      <DemoDaysStats demoDays={sortedDemoDays} missions={missions} users={users} />
      <section className="demo-days-block-container">
        <AllDemoDays demoDayIdsForMissions={demoDayIdsForMissions} demoDays={takeWhile(sortedDemoDays, (_demoDay, index) => index < displayLimit)} />
        {displayLimit < sortedDemoDays.length && (
          <button
            className="button-white-outline centered"
            onClick={() => setDisplayLimit((currentLimit) => currentLimit + DEMO_DAYS_DISPLAY_BLOCK_SIZE)}
          >
            + Load More
          </button>
        )}
      </section>
      <NextLaunch title="Next Demo Day" hideLink />
    </Layout>
  )
}

function DemoDaysHeader() {
  return (
    <header className="page-header">
      <span className="color-tint"></span>
      <div className="text-container">
        <h1>Demo Days</h1>
        <p>It all starts with an idea.</p>
      </div>
    </header>
  )
}

export default DemoDays

function DemoDaysStory() {
  return (
    <section className="text-block-container longer-text-block">
      <h2>Early on in our company, it became apparent that we had assembled a team of problem solvers and creators.</h2>
      <div className="two-column-text">
        <p>Lunch conversations quickly turned into whiteboarding sessions of product and technology ideas. While these times were fun, we all felt like we wanted to do more.  Talking about stuff is great, but creating is better. To put some structure around this, we established “Demo Day” - a half-day, internal pow-wow to show off some cool things we had all built. We had our doubts. When we would find the time to do this extra work just for fun? What should I make? Will I fail? Will I embarrass myself with my idea?</p>
        <p>The first demo day arrived, and all those concerns quickly dissolved. Each person’s creativity and passion were evident as they showed off their project to the rest of the team. The only question on people’s minds was...what’s next for your idea?</p>
        <p>Demo Day continues to evolve and always will. Recently, we’ve evolved to a virtual approach for Demo Day, but we look forward to the day we can safely host all of our innovators together in one room again. Virtual or in-person, each Demo Day is a collection of both individual projects and team projects ranging from simple hacks to robust product ideas. Each and every one is an expression of creative passion to take an idea and make it real. These days fuel our creativity and need to explore, and our team looks forward to having a day dedicated to learning and hearing from our peers.</p>
        <p>See a glimpse of our history below.</p>
      </div>
    </section>
  )
}

function DemoDaysStats({ demoDays, missions, users, }) {
  return (
    <section className="grid-block-container">
      <ul>
        <li>
          <h2>{demoDays.length}</h2>
          <p>Number of Demo Days</p>
        </li>
        <li>
          <h2>{missions.length}</h2>
          <p>Number of Missions</p>
        </li>
        <li>
          <h2>{contributorCount(missions, users)}</h2>
          <p>Contributors</p>
        </li>
      </ul>
    </section>
  )
}

function AllDemoDays({ demoDays, demoDayIdsForMissions, }) {
  const hasMissions = (id) => demoDayIdsForMissions.indexOf(id) > -1

  return (
    <>
      {demoDays.map(({ id, fields, slug }) => (
        <DemoDaySummary key={id} {...fields} slug={slug} hasMissions={hasMissions(id)} />
      ))}
    </>
  )
}

export const query = graphql`
  {
    allWpDemoDay {
      nodes {
        id
        slug
        fields: demo_day_fields {
          description
          displayNumber
          launchDate
          number
        }
      }
    }
    allWpMission {
      nodes {
        id
        fields: mission_fields {
          demoDay {
            ... on WpDemoDay {
              id
            }
          }
          teamMembers {
            name
          }
          commander {
            name
          }
        }
      }
    }
    allWpUser {
      nodes {
        id
      }
    }
  }
`
